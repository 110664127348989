import React from 'react'

export default function Header() {
  return (
    <>
      <div className="main-header">

<span className=" text-left">
    <h1 className="text-4xl font-bold mb-4">All The Best!</h1>
    <p className="text-2xl font-semibold">
        Answer all questions, and submit
        <br />
        your answers in preferable way
    </p>
</span>

<span className=" text-xl">
    <p className='text-left'><b>Name:</b> abcdef</p>
    <p className='text-left'><b>Candidate id:</b> 567</p>
    <p className='text-left'><b>Age:</b> 20</p>
    <p className='text-left'><b>Subject:</b> geography</p>
</span>

</div>

</>
  )
}
